import React from "react";
import Header from "../components/atoms/Header";
import styles from "./UploadPage.module.css";
import UploadForm from "../components/uploadForm/UploadForm";

export default function UploadPage() {
  return (
    <div className={styles.container}>
      <Header plus landing />
      <UploadForm />
    </div>
  );
}
