import React from "react";
import styles from "./GeneralInput.module.css";

export default function TimeInput({ first, setTime, value }) {
  return (
    <div className={styles.container}>
      <label className={styles.label}>
        {first ? "Reporting" : "Checkout"} Time
      </label>
      <input
        className={styles.input}
        value={value}
        onChange={setTime}
        type="time"
        placeholder="HH:MM"
      />
    </div>
  );
}
