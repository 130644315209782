import React, { useState } from "react";
import Upload from "../../components/inputs/Upload";
import { ReactComponent as Remove } from "../../assets/icons/remove.svg";
import { useNavigate } from "react-router-dom";
import apiService from "../../services/apiService";
import {
  convertBytesToKB,
  convertNestedObjectToArray,
  shortenString,
} from "../../utils/uploadFunctions";
import ActionButton from "../atoms/buttons/ActionButton";
import DropDown from "../inputs/DropDown";
import { countries, ranks } from "../../utils/dropdownValues";
import styles from "./UploadForm.module.css";

export default function UploadForm() {
  const [fileObject, setFileObject] = useState({});
  const [fileArray, setFileArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [rank, setRank] = useState("");
  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const removeFile = () => {
    navigate(0);
  };

  const uploadRoster = async (file) => {
    setIsLoading(true);
    if (file.length === 1) {
      const upload = await apiService.uploadFile(file, rank, country);
      if (upload) {
        setIsLoading(false);
        navigate("/result", { state: upload });
      }
    } else {
      setIsLoading(false);
      setErrorMessage("Something went wrong");
    }
  };

  const preloadFile = (e) => {
    setErrorMessage("");
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      if (newFiles[0].size > 150000) {
        setErrorMessage("Please make sure the image file size is under 150KB.");
      } else {
        setFileObject(newFiles);
        setFileArray(convertNestedObjectToArray(newFiles));
      }
    }
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.formContent}>
        <DropDown
          className={styles.dropdown1}
          setValue={(value) => setCountry(value)}
          options={countries}
        />
        <DropDown
          className={styles.dropdown2}
          setValue={(value) => setRank(value)}
          options={ranks}
        />
        <p className={styles.instructions}>
          Download your roster from ecrew. Please make sure that the roster is
          downloaded as a pdf with time set to UTC and the amount of days equal
          to the amount of days in the month selected.
          <br />
          <br />
          Disclaimer: This feature is still in testing. Inevitably, this feature
          may not work on some rosters. It would be much appreciated if you
          would email your roster to sectorcheck1@gmail.com if the feature did
          not work for you. This will help in building a more stable version.
          <br />
          <br />
          This feature currently does not work for LTC, TRI, and TRE rosters.
        </p>
        <div className={styles.upload}>
          <Upload className={styles.upload} onChange={preloadFile} />
        </div>
        {Object.keys(fileObject).map((property) => {
          let file = fileObject[property];
          return (
            <div className={styles.object} key={property}>
              <p>{shortenString(file.name)}</p>
              <p>{convertBytesToKB(file.size)} KB</p>
              <Remove
                width={15}
                stroke={"black"}
                style={{ cursor: "pointer" }}
                onClick={() => removeFile(property)}
              />
            </div>
          );
        })}
        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
        <div className={styles.button}>
          <ActionButton
            disabled={!rank || !country || !fileArray[0]}
            loading={isLoading}
            onClick={() => uploadRoster(fileArray)}
            buttonText={"UPLOAD"}
          />
        </div>
      </div>
    </div>
  );
}
