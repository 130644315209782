import React, { useState } from "react";
import TimePairInputs from "../fields/TimePairInputs";
import CheckBox from "../inputs/CheckBox";
import styles from "./GeneralSection.module.css";

export default function AirportsDutiesSection() {
  const [activated, setActivated] = useState(false);
  return (
    <div className={styles.container}>
      <h2 className={styles.sectionHeader}>AIRPORT DUTY</h2>
      <TimePairInputs
        airportDuty
        resetActivationField={() => setActivated(false)}
        activated={activated}
      />
      <p>*Please enter times in UTC</p>
      <CheckBox
        checked={activated}
        onClick={() => setActivated(!activated)}
        label="Activated?"
      />
    </div>
  );
}
