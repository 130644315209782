export const convertNestedObjectToArray = (nestedObj) => {
  return Object.keys(nestedObj).map((key) => nestedObj[key]);
};

export const convertBytesToKB = (bytes) => {
  return Math.round(bytes / 1000);
};

export const shortenString = (string) => {
  if (string.length > 30) {
    const shortenedString = string.slice(0, 29);
    return shortenedString.concat("...");
  } else {
    return string;
  }
};
