import { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ContractDetailsContext } from "./context/contractDetailsContext";
import FormPage from "./pages/FormPage";
import LandingPage from "./pages/LandingPage";
import UploadPage from "./pages/UploadPage";
import ResultPage from "./pages/ResultPage";
import FlightDetailsPage from "./pages/FlightDetailsPage";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  const { details } = useContext(ContractDetailsContext);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            details.country && details.rank ? <FormPage /> : <LandingPage />
          }
        />
        <Route path="/plus" element={<UploadPage />} />
        <Route path="/result" element={<ResultPage />} />
        <Route path="/lis-departures" element={<FlightDetailsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
