import React from "react";
import styles from "./Header.module.css";

export default function Header({ landing, plus }) {
  const refresh = () => {
    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>
        <span onClick={refresh} className={styles.text}>
          SECTOR CHECK
          {plus && <span className={styles.plus}>+</span>}
        </span>
      </h1>
      {landing && <p className={styles.versionText}>V3.0.3.0</p>}
    </div>
  );
}
