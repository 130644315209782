import React from "react";
import { ReactComponent as LoadingSpinner } from "../../../assets/icons/loading-spinner.svg";
import styles from "./ActionButton.module.css";

export default function ActionButton({
  large,
  buttonText,
  disabled,
  onClick,
  loading,
}) {
  return (
    <button
      style={{
        fontSize: large ? "1.9rem" : "1.35rem",
        color: loading ? "#a6dfe4" : "white",
      }}
      onClick={onClick}
      className={styles.button}
      disabled={loading || disabled}
    >
      {loading && (
        <div className={styles.spinnerContainer}>
          <LoadingSpinner width="60" />
        </div>
      )}
      {buttonText}
    </button>
  );
}
