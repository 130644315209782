const baseUrl =
  process.env.REACT_APP_API_BASE_URL || "http://127.0.0.1:5000/api";
const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
const cloudinaryRootDomain = process.env.REACT_APP_CLOUDINARY_ROOT_DOMAIN;

const apiService = {
  getPerDiemFactor: async (rank, contractType) => {
    try {
      const response = await fetch(
        `${baseUrl}/per-diem-factor?rank=${rank}&contractType=${contractType}`
      );
      const data = await response.json();
      data.success = true;
      return data;
    } catch {
      return { success: false };
    }
  },
  getDistance: async (code1, code2) => {
    const response = await fetch(
      `${baseUrl}/calculate-distance?code1=${code1}&code2=${code2}`
    );
    if (response.ok) {
      const data = await response.json();
      data.success = true;
      return data;
    } else {
      return { success: false };
    }
  },
  getAirportDutyFactor: async (time, activated = false) => {
    let activatedParam = "";
    if (activated) {
      activatedParam = "activated=True";
    }
    const response = await fetch(
      `${baseUrl}/calculate-airport-duty?time=${time}&${activatedParam}`
    );
    if (response.ok) {
      const data = await response.json();
      data.success = true;
      return data;
    } else {
      return { success: false };
    }
  },
  getRosterDetails: async (url) => {
    const response = await fetch(`${baseUrl}/get-roster-details?url=${url}`);
    if (response.ok) {
      const data = await response.json();
      data.success = true;
      return data;
    } else {
      return { success: false };
    }
  },
  uploadFile: async (file, rank) => {
    try {
      const formData = new FormData();
      formData.append("file", file[0]);
      formData.append("upload_preset", uploadPreset);
      formData.append("resource_type", "image");

      const response = await fetch(`${cloudinaryRootDomain}/upload`, {
        method: "POST",
        body: formData,
      });
      if (response.status === 200) {
        const data = await response.json();
        const apiResponse = await fetch(
          `${baseUrl}/get-roster-details?url=${data.url}&rank=${rank}`
        );
        if (apiResponse.ok) {
          const apiData = await apiResponse.json();
          return apiData;
        } else {
          return { fail: true };
        }
      }
    } catch (e) {
      return { fail: true };
    }
  },
};

export default apiService;
