import React from "react";
import { getVariablePay } from "../../utils/payslipFunctions";
import styles from "./PayslipTotal.module.css";

export default function PayslipTotal({ duties }) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>TOTAL</div>
      <div className={styles.entry}>{getVariablePay(duties)}</div>
    </div>
  );
}
