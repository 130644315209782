import React, { createContext, useState } from "react";

export const ToastContext = createContext();

const ContextManager = ({ children }) => {
  const [toast, setToast] = useState(null);

  return (
    <ToastContext.Provider value={{ toast, setToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export default ContextManager;
