import React from "react";
import styles from "./PayslipHeaders.module.css";

export default function PayslipHeaders() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.titleBox}>VARIABLE PAY</div>
      </div>
      <div className={styles.headers}>
        <div className={styles.headerBox}>Date</div>
        <div className={styles.headerBox}>Summary</div>
        <div className={styles.headerBox}>Description</div>
        <div className={styles.headerBox}>Total Amount</div>
        {
          //<div className={styles.headerBox}>Tax Free Amount</div>
        }
      </div>
    </div>
  );
}
