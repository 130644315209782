import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { PerDiemsTotalContext } from "../../context/perDiemsTotalContext";
import { ToastContext } from "../../context/toastContext";
import ActionButton from "../atoms/buttons/ActionButton";
import SectorPairInputs from "../fields/SectorPairInputs";
import styles from "./GeneralSection.module.css";

export default function SectorsFlownSection() {
  const { dispatch, state } = useContext(PerDiemsTotalContext);
  const { setToast } = useContext(ToastContext);

  const addReturnJourney = () => {
    const previousDuty = state.sectorDuties.at(-1);
    const duty = {
      id: uuidv4(),
      departureAirport: previousDuty.arrivalAirport,
      arrivalAirport: previousDuty.departureAirport,
      distance: previousDuty.distance,
      sectorLength: previousDuty.sectorLength,
      paymentOwed: previousDuty.paymentOwed,
    };
    dispatch({
      type: "ADD_SECTOR_DUTY",
      payload: duty,
    });
    setToast({
      title: "Sector Duty",
      message: `Return journey succesfully added`,
    });
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.sectionHeader}>SECTORS FLOWN</h2>
      <SectorPairInputs />
      <ActionButton
        disabled={!state.sectorDuties.at(-1)}
        onClick={addReturnJourney}
        buttonText={"ADD RETURN JOURNEY"}
      />
    </div>
  );
}
