import React, { createContext, useReducer } from "react";

export const PerDiemsTotalContext = createContext();

const initialState = {
  sectorDuties: [],
  airportDuties: [],
  leaveDays: {},
  wfyDays: {},
  nightStops: {},
  trainingDays: {},
  shortNoticeChanges: {},
  infringedDaysOff: {},
  disruptedDaysOff: {},
  instructorDays: {},
};

export const TotalsReducer = (state, action) => {
  switch (action.type) {
    case "ADD_SECTOR_DUTY":
      return {
        ...state,
        sectorDuties: [...state.sectorDuties, action.payload],
      };
    case "REMOVE_SECTOR_DUTY":
      return {
        ...state,
        sectorDuties: state.sectorDuties.filter(
          (duty) => duty.id !== action.payload
        ),
      };
    case "ADD_AIRPORT_DUTY":
      return {
        ...state,
        airportDuties: [...state.airportDuties, action.payload],
      };
    case "REMOVE_AIRPORT_DUTY":
      return {
        ...state,
        airportDuties: state.airportDuties.filter(
          (duty) => duty.id !== action.payload
        ),
      };
    case "ADD_LEAVE_PAY":
      return {
        ...state,
        leaveDays: action.payload,
      };
    case "REMOVE_LEAVE_PAY":
      return {
        ...state,
        leaveDays: {},
      };
    case "ADD_WFY_PAY":
      return {
        ...state,
        wfyDays: action.payload,
      };
    case "REMOVE_WFY_PAY":
      return {
        ...state,
        wfyDays: {},
      };
    case "ADD_TRAINING_PAY":
      return {
        ...state,
        trainingDays: action.payload,
      };
    case "REMOVE_TRAINING_PAY":
      return {
        ...state,
        trainingDays: {},
      };
    case "ADD_SNC_PAY":
      return {
        ...state,
        shortNoticeChanges: action.payload,
      };
    case "REMOVE_SNC_PAY":
      return {
        ...state,
        shortNoticeChanges: {},
      };
    case "ADD_INSTRUCTOR_PAY":
      return {
        ...state,
        instructorDays: action.payload,
      };
    case "REMOVE_INSTRUCTOR_PAY":
      return {
        ...state,
        instructorDays: {},
      };
    case "ADD_NIGHT_STOP_PAY":
      return {
        ...state,
        nightStops: action.payload,
      };
    case "REMOVE_NIGHT_STOP_PAY":
      return {
        ...state,
        nightStops: {},
      };
    case "ADD_DISRUPTED_DAYS_OFF":
      return {
        ...state,
        disruptedDaysOff: action.payload,
      };
    case "REMOVE_DISRUPTED_DAYS_OFF":
      return {
        ...state,
        disruptedDaysOff: {},
      };
    case "ADD_INFRINGED_DAYS_OFF":
      return {
        ...state,
        infringedDaysOff: action.payload,
      };
    case "REMOVE_INFRINGED_DAYS_OFF":
      return {
        ...state,
        infringedDaysOff: {},
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

const ContextManager = ({ children }) => {
  const [state, dispatch] = useReducer(TotalsReducer, initialState);

  return (
    <PerDiemsTotalContext.Provider value={{ state, dispatch }}>
      {children}
    </PerDiemsTotalContext.Provider>
  );
};

export default ContextManager;
