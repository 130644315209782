import React from "react";
import styles from "./GeneralInput.module.css";

export default function NumberInput({ setDays, value, error }) {
  return (
    <div className={styles.container}>
      <label className={styles.label}>Days</label>
      <input
        style={{ boxShadow: error && "0px 0px 20px red" }}
        type="number"
        step={1}
        min={0}
        max={31}
        className={styles.input}
        value={value}
        onChange={setDays}
        placeholder={"0"}
      />
      {error && <p className={styles.error}>Enter a value between 0 to 31</p>}
    </div>
  );
}
