import React, { useContext, useEffect, useState } from "react";
import styles from "./SuccessToast.module.css";
import { ReactComponent as Remove } from "../../../assets/icons/remove.svg";
import { ReactComponent as CheckMark } from "../../../assets/icons/checkmark.svg";
import { ToastContext } from "../../../context/toastContext";

export default function SuccessToast({ toastDetails }) {
  const { setToast } = useContext(ToastContext);
  const [position, setPosition] = useState(-500);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    setPosition(12);
    const timeOut1 = setTimeout(() => {
      setOpacity(0);
    }, 3000);
    const timeOut2 = setTimeout(() => {
      setToast(0);
    }, 3500);
    return () => {
      clearTimeout(timeOut1);
      clearTimeout(timeOut2);
    };
  }, [setToast]);

  return (
    <div
      style={{ right: `${position}px`, opacity }}
      className={styles.container}
    >
      <Remove
        onClick={() => setToast(null)}
        className={styles.remove}
        width={15}
      />
      <div className={styles.titleContainer}>
        <div className={styles.checkmarkContainer}>
          <CheckMark className={styles.checkmark} width={15} />
        </div>
        <p className={styles.title}>{toastDetails.title}</p>
      </div>
      <p className={styles.message}>{toastDetails.message}</p>
    </div>
  );
}
