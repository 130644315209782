import React, { useState } from "react";
import { ReactComponent as Caret } from "../../assets/icons/caret.svg";
import styles from "./DropDown.module.css";

export default function DropDown({ setValue, options, className }) {
  const [selection, setSelection] = useState(options[0].option);
  const [showOptions, setShowOptions] = useState(false);

  const chooseOption = (option) => {
    setSelection(() => option.option);
    setValue(option.value);
    setShowOptions(() => false);
  };

  return (
    <div
      onBlur={() => setShowOptions(false)}
      tabIndex={0}
      className={className}
    >
      <div
        className={styles.select}
        onClick={() => setShowOptions(!showOptions)}
      >
        {selection}
        <Caret className={styles.caret} />
      </div>
      {showOptions && (
        <ul className={styles.optionContainer}>
          {options.slice(1).map((option) => (
            <li
              onClick={() => chooseOption(option)}
              className={styles.option}
              value={option.value}
              key={option.value}
            >
              {option.option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
