import React, { useContext } from "react";
import { ReactComponent as Arrow } from "../assets/icons/right-arrow.svg";
import { ReactComponent as Remove } from "../assets/icons/remove.svg";
import styles from "./Log.module.css";
import { PerDiemsTotalContext } from "../context/perDiemsTotalContext";

export default function PaidDayLog({
  duty,
  timeBased,
  dayBased,
  category,
  type,
}) {
  const { dispatch } = useContext(PerDiemsTotalContext);

  const removePayment = () => {
    dispatch({
      type: type,
      payload: duty.id,
    });
  };

  return (
    <div className={styles.dayBasedContainer}>
      {timeBased && (
        <p className={styles.timeBasedValue}>
          <span className={styles.routeValue}>{duty.reportTime}</span>
          <Arrow className={styles.arrow} />{" "}
          <span className={styles.routeValue}>{duty.checkoutTime}</span>
        </p>
      )}
      {dayBased && (
        <p className={styles.dayBasedValue}>
          {duty.days} day{duty.days === "1" ? "" : "s"}
        </p>
      )}
      <p className={styles.dayBasedValue}>{category}</p>
      <p className={styles.dayBasedValue}>{duty.paymentOwed.toFixed(2)}</p>
      <Remove onClick={removePayment} className={styles.remove} />
    </div>
  );
}
