export const getDescription = (duty) => {
  if (duty[0] === "SECTOR_DUTY") {
    const string = `${duty[1]} sector duty ${duty[3] ? "with night stop" : ""}`;
    return string;
  }
  if (duty[0] === "LEAVE") {
    return "Leave day";
  }
  if (duty[0] === "DDO") {
    return "Disrupted day off";
  }
  if (duty[0] === "IDO") {
    return "Infringed day off";
  }
  if (duty[0] === "OFFICE") {
    return "Office duty";
  }
  if (duty[0] === "OFF" || duty[0] === "STANDBY") {
    return "Night stop pay";
  }
  if (duty[0] === "TRAINING") {
    return `Training ${duty[2] ? "with night stop" : ""}`;
  }
};

export const getPayment = (duty) => {
  if (duty[0] === "SECTOR_DUTY") {
    const payment = (duty[2] + duty[3]).toFixed(2);
    return payment;
  }
  if (duty[0] === "TRAINING") {
    const payment = (duty[1] + duty[2]).toFixed(2);
    return payment;
  }
  const payment = duty[1].toFixed(2);
  return payment;
};

export const getVariablePay = (duties) => {
  let totalVariablePay = 0;
  for (const duty in duties) {
    if (duties[duty][0] === "SECTOR_DUTY") {
      totalVariablePay = totalVariablePay + duties[duty][2] + duties[duty][3];
    } else if (duties[duty][0] === "TRAINING") {
      totalVariablePay = totalVariablePay + duties[duty][1] + duties[duty][2];
    } else {
      totalVariablePay = totalVariablePay + duties[duty][1];
    }
  }
  return totalVariablePay.toFixed(2);
};
