import React from "react";
import { useLocation } from "react-router-dom";
import PayslipEntries from "../components/payslip/PayslipEntries";
import PayslipHeaders from "../components/payslip/PayslipHeaders";
import PayslipTotal from "../components/payslip/PayslipTotal";
import styles from "./ResultPage.module.css";

export default function ResultPage() {
  const { state } = useLocation();

  if (!state.fail) {
    return (
      <div className={styles.container}>
        <PayslipHeaders />
        <PayslipEntries duties={state} />
        <PayslipTotal duties={state} />
        <br />
        <br />
        <p>
          Was this helpful? If so, feel free to buy me a coffee by clicking{" "}
          <a href="https://www.buymeacoffee.com/sectorcheck">here</a>. The funds
          collected will be used to pay for upgrades to the server to host more
          traffic. Hopefully this can be made available to more countries.
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <p>
          Looks like something went wrong. This feature is still in testing.
          Inevitably, this feature may not work on some rosters. It would be
          much appreciated if you would email your roster to
          sectorcheck1@gmail.com if the feature did not work for you. This will
          help in building a more stable version.
          <br />
          <br />
          This feature currently does not work for LTC, TRI, and TRE rosters.
        </p>
      </div>
    );
  }
}
