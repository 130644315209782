import React from "react";
import Header from "../components/atoms/Header";
import SetupForm from "../components/setupForm/SetupForm";
import styles from "./LandingPage.module.css";

export default function SetUpPage() {
  return (
    <div className={styles.container}>
      <Header landing />
      <SetupForm />
    </div>
  );
}
