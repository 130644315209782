import React, { useContext } from "react";
import { ContractDetailsContext } from "../../context/contractDetailsContext";
import { PerDiemsTotalContext } from "../../context/perDiemsTotalContext";
import { getVariablePay } from "../../utils/helperFunctions";
import ActionButton from "../atoms/buttons/ActionButton";
import styles from "./CalculationSection.module.css";

export default function CalculationSection() {
  const { state, dispatch } = useContext(PerDiemsTotalContext);
  const { details } = useContext(ContractDetailsContext);

  const salary = details.basicSalary.toString().split(".");
  const totalVariablePay = getVariablePay(state);
  const variablePay = totalVariablePay.toString().split(".");
  const total = details.basicSalary + totalVariablePay;
  const totalPay = total.toString().split(".");

  return (
    <div className={styles.container}>
      <h2 className={styles.sectionHeader}>CALCULATIONS</h2>
      <div className={styles.calculationContainer}>
        <div className={styles.totalContainer}>
          <p className={styles.boxHeader}>TOTAL GROSS OWED</p>
          <div className={styles.paymentContainer}>
            <span className={styles.euro}>€</span>
            <span className={styles.amount}>{totalPay[0]}</span>
            <span className={styles.cents}>.{totalPay[1].slice(0, 2)}</span>
          </div>
        </div>
        <div className={styles.basicContainer}>
          <p className={styles.boxHeader}>BASIC SALARY</p>
          <div className={styles.paymentContainer}>
            <span className={styles.smallEuro}>€</span>
            <span className={styles.smallAmount}>{salary[0]}</span>
            <span className={styles.smallCents}>.{salary[1].slice(0, 2)}</span>
          </div>
        </div>
        <div className={styles.variableContainer}>
          <p className={styles.boxHeader}>VARIABLE PAY</p>
          <div className={styles.paymentContainer}>
            <span className={styles.smallEuro}>€</span>
            <span className={styles.smallAmount}>{variablePay[0]}</span>
            <span className={styles.smallCents}>
              .{variablePay[1] ? variablePay[1].slice(0, 2) : "00"}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ActionButton
          onClick={() => dispatch({ type: "RESET" })}
          buttonText={"RESET"}
        />
      </div>
    </div>
  );
}
