import React, { useContext } from "react";
import { ReactComponent as Arrow } from "../assets/icons/right-arrow.svg";
import { ReactComponent as Remove } from "../assets/icons/remove.svg";
import styles from "./Log.module.css";
import { PerDiemsTotalContext } from "../context/perDiemsTotalContext";

export default function SectorLog({ duty }) {
  const { dispatch } = useContext(PerDiemsTotalContext);

  const removeSector = () => {
    dispatch({
      type: "REMOVE_SECTOR_DUTY",
      payload: duty.id,
    });
  };

  return (
    <div className={styles.container}>
      <p className={styles.value}>
        <span className={styles.routeValue}>{duty.departureAirport}</span>
        <Arrow className={styles.arrow} />{" "}
        <span className={styles.routeValue}>{duty.arrivalAirport}</span>
      </p>
      <p className={styles.value}>{duty.distance}</p>
      <p className={styles.value}>{duty.sectorLength}</p>
      <p className={styles.value}>{duty.paymentOwed.toFixed(2)}</p>
      <Remove onClick={removeSector} className={styles.remove} />
    </div>
  );
}
