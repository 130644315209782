import React, { useContext, useState } from "react";
import NumberInput from "../inputs/NumberInput";
import ActionButton from "../atoms/buttons/ActionButton";
import { ContractDetailsContext } from "../../context/contractDetailsContext";
import { PerDiemsTotalContext } from "../../context/perDiemsTotalContext";
import styles from "./GeneralPairInputs.module.css";
import { ToastContext } from "../../context/toastContext";

export default function DayCountField({
  leave,
  training,
  snc,
  instructor,
  nightStop,
  disrupted,
  infringed,
  wfy,
}) {
  const [days, setDays] = useState("");
  const [error, setError] = useState("");

  const { details } = useContext(ContractDetailsContext);
  const { dispatch } = useContext(PerDiemsTotalContext);
  const { setToast } = useContext(ToastContext);

  const submit = async () => {
    if (days.includes(".") || days.includes("-") || days.length > 2) {
      setError(true);
    } else {
      setError(false);
      if (leave) {
        const paymentOwed = details.leavePay * parseInt(days);
        const duty = {
          paymentOwed,
          days,
        };
        dispatch({
          type: "ADD_LEAVE_PAY",
          payload: duty,
        });
        setToast({
          title: "Leave Days",
          message: `${days} leave days sucessfully added`,
        });
      }
      if (wfy) {
        const paymentOwed = details.wfyPay * parseInt(days);
        const duty = {
          paymentOwed,
          days,
        };
        dispatch({
          type: "ADD_WFY_PAY",
          payload: duty,
        });
        setToast({
          title: "Willing To Fly",
          message: `${days} willing to fly days sucessfully added`,
        });
      }
      if (training) {
        const paymentOwed =
          details.nominalSectorPay *
          details.groundTrainingFactor *
          parseInt(days);
        const duty = {
          paymentOwed,
          days,
        };
        dispatch({
          type: "ADD_TRAINING_PAY",
          payload: duty,
        });
        setToast({
          title: "Training Days",
          message: `${days} training days sucessfully added`,
        });
      }
      if (snc) {
        const paymentOwed = details.sncValue * parseInt(days);
        const duty = {
          paymentOwed,
          days,
        };
        dispatch({
          type: "ADD_SNC_PAY",
          payload: duty,
        });
        setToast({
          title: "Short Notice Changes",
          message: `${days} short notice changes sucessfully added`,
        });
      }
      if (instructor) {
        const paymentOwed = details.instructorFactor * parseInt(days);
        const duty = {
          paymentOwed,
          days,
        };
        dispatch({
          type: "ADD_INSTRUCTOR_PAY",
          payload: duty,
        });
        setToast({
          title: "Instructor Days",
          message: `${days} instructor days sucessfully added`,
        });
      }
      if (nightStop) {
        const paymentOwed =
          details.nightStopFactor * details.nominalSectorPay * parseInt(days);
        const duty = {
          paymentOwed,
          days,
        };
        dispatch({
          type: "ADD_NIGHT_STOP_PAY",
          payload: duty,
        });
        setToast({
          title: "Night Stops",
          message: `${days} night stops sucessfully added`,
        });
      }
      if (disrupted) {
        const idoValue = details.idoValue;
        let paymentOwed;
        if (details.trainerType === "ltc") {
          paymentOwed = parseInt(days) * ((idoValue + 88) / 2);
        } else if (details.trainerType === "tri") {
          paymentOwed = parseInt(days) * ((idoValue + 106) / 2);
        } else if (details.trainerType === "tre") {
          paymentOwed = parseInt(days) * ((idoValue + 125) / 2);
        } else {
          paymentOwed = parseInt(days) * (idoValue / 2);
        }
        const duty = {
          paymentOwed,
          days,
        };
        dispatch({
          type: "ADD_DISRUPTED_DAYS_OFF",
          payload: duty,
        });
        setToast({
          title: "Disrupted Days Off",
          message: `${days} disrupted days off sucessfully added`,
        });
      }
      if (infringed) {
        const idoValue = details.idoValue;
        let paymentOwed;
        if (details.trainerType === "ltc") {
          paymentOwed = parseInt(days) * (idoValue + 88);
        } else if (details.trainerType === "tri") {
          paymentOwed = parseInt(days) * (idoValue + 106);
        } else if (details.trainerType === "tre") {
          paymentOwed = parseInt(days) * (idoValue + 125);
        } else {
          paymentOwed = parseInt(days) * idoValue;
        }
        const duty = {
          paymentOwed,
          days,
        };
        dispatch({
          type: "ADD_INFRINGED_DAYS_OFF",
          payload: duty,
        });
        setToast({
          title: "Infrindged Days Off",
          message: `${days} infringed days off sucessfully added`,
        });
      }
    }
  };

  return (
    <div className={styles.container}>
      <NumberInput
        error={error}
        value={days}
        setDays={(e) => setDays(e.target.value)}
        first
      />
      <span className={styles.buttonContainer}>
        <ActionButton
          disabled={days === ""}
          buttonText={"ADD"}
          onClick={submit}
        />
      </span>
    </div>
  );
}
