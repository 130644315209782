import React from "react";
import styles from "./GeneralInput.module.css";

export default function AirportCodeInput({ first, setCode, errorCode, value }) {
  return (
    <div className={styles.container}>
      <label className={styles.label}>
        IATA Code ({first ? "Departure" : "Arrival"})
      </label>
      <input
        style={{ boxShadow: errorCode && "0px 0px 20px red" }}
        className={styles.input}
        value={value}
        onChange={setCode}
        maxLength={3}
        placeholder={first ? "E.G. LIS" : "E.G. CDG"}
      />
      {errorCode && (
        <p className={styles.error}>Please enter a valid IATA Code</p>
      )}
    </div>
  );
}
