import React, { useContext } from "react";
import { PerDiemsTotalContext } from "../../context/perDiemsTotalContext";
import SectorLog from "../SectorLog";
import styles from "./LogSection.module.css";

export default function SectorLogSection() {
  const { state } = useContext(PerDiemsTotalContext);

  if (state.sectorDuties.length) {
    return (
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <p className={styles.header}>Route</p>
          <p className={styles.header}>Distance</p>
          <p className={styles.header}>Sector Length</p>
          <p className={styles.header}>Payment Owed</p>
        </div>
        {state.sectorDuties.map((duty) => (
          <SectorLog key={duty.id} duty={duty} />
        ))}
      </div>
    );
  } else {
    return null;
  }
}
