import React, { useContext } from "react";
import { PerDiemsTotalContext } from "../../context/perDiemsTotalContext";
import PaidDayLog from "../PaidDayLog";
import styles from "./LogSection.module.css";

export default function PaidDayLogSection() {
  const { state } = useContext(PerDiemsTotalContext);
  return (
    <div className={styles.container}>
      <div className={styles.dayBasedHeaderContainer}>
        <p className={styles.dayBasedHeader}>Input</p>
        <p className={styles.dayBasedHeader}>Category</p>
        <p className={styles.dayBasedHeader}>Payment Owed</p>
      </div>
      {state.airportDuties.map((duty) => (
        <PaidDayLog
          background={"#FF10B6"}
          category="Airport Duty"
          timeBased
          key={duty.id}
          duty={duty}
          type={"REMOVE_AIRPORT_DUTY"}
        />
      ))}
      {state.leaveDays.paymentOwed && (
        <PaidDayLog
          background={"#FF10B6"}
          dayBased
          category="Leave Days"
          key={"leaveDays"}
          duty={state.leaveDays}
          type={"REMOVE_LEAVE_PAY"}
        />
      )}
      {state.wfyDays.paymentOwed && (
        <PaidDayLog
          background={"#FF10B6"}
          dayBased
          category="WFY Days"
          key={"wfyDays"}
          duty={state.wfyDays}
          type={"REMOVE_WFY_PAY"}
        />
      )}
      {state.nightStops.paymentOwed && (
        <PaidDayLog
          background={"#FF10B6"}
          dayBased
          category="Night Stops"
          key={"nightStops"}
          duty={state.nightStops}
          type={"REMOVE_NIGHT_STOP_PAY"}
        />
      )}
      {state.trainingDays.paymentOwed && (
        <PaidDayLog
          background={"#FF10B6"}
          dayBased
          category="Training Days"
          key={"trainingDays"}
          duty={state.trainingDays}
          type={"REMOVE_TRAINING_PAY"}
        />
      )}
      {state.shortNoticeChanges.paymentOwed && (
        <PaidDayLog
          background={"#FF10B6"}
          dayBased
          category="Short Notice Changes"
          key={"shortNoticeChanges"}
          duty={state.shortNoticeChanges}
          type={"REMOVE_SNC_PAY"}
        />
      )}
      {state.infringedDaysOff.paymentOwed && (
        <PaidDayLog
          background={"#FF10B6"}
          dayBased
          category="Infringed Days Off"
          key={"infringedDaysOff"}
          duty={state.infringedDaysOff}
          type={"REMOVE_INFRINGED_DAYS_OFF"}
        />
      )}
      {state.disruptedDaysOff.paymentOwed && (
        <PaidDayLog
          background={"#FF10B6"}
          dayBased
          category="Disrupted Days Off"
          key={"disruptedDaysOff"}
          duty={state.disruptedDaysOff}
          type={"REMOVE_DISRUPTED_DAYS_OFF"}
        />
      )}
      {state.instructorDays.paymentOwed && (
        <PaidDayLog
          background={"#FF10B6"}
          dayBased
          category="Instructor Days"
          key={"instructorDays"}
          duty={state.instructorDays}
          type={"REMOVE_INSTRUCTOR_PAY"}
        />
      )}
    </div>
  );
}
