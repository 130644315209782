import React from "react";
import styles from "./Upload.module.css";

export default function FileInput({ onChange, className }) {
  return (
    <div className={styles.container}>
      <p className={styles.text}>Drag and drop roster here or</p>
      <label className={styles.label} htmlFor={"upload-input"}>
        Browse
      </label>
      <input
        className={styles.input}
        accept={".pdf"}
        onChange={onChange}
        id={"upload-input"}
        type="file"
      />
    </div>
  );
}
