import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AirportCodeInput from "../inputs/AirportCodeInput";
import ActionButton from "../atoms/buttons/ActionButton";
import { ReactComponent as Arrow } from "../../assets/icons/right-arrow.svg";
import apiService from "../../services/apiService";
import { ContractDetailsContext } from "../../context/contractDetailsContext";
import { PerDiemsTotalContext } from "../../context/perDiemsTotalContext";
import styles from "./GeneralPairInputs.module.css";
import { ToastContext } from "../../context/toastContext";
import CheckBox from "../inputs/CheckBox";

export default function AirportPairInputs() {
  const [code1, setCode1] = useState("");
  const [errorCode1, setErrorCode1] = useState(false);
  const [code2, setCode2] = useState("");
  const [errorCode2, setErrorCode2] = useState(false);
  const [lineTraining, setLineTraining] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { details } = useContext(ContractDetailsContext);
  const { dispatch } = useContext(PerDiemsTotalContext);
  const { setToast } = useContext(ToastContext);

  const submit = async () => {
    if (!/^[A-Z]+$/.test(code1)) {
      setErrorCode1(true);
    }
    if (!/^[A-Z]+$/.test(code2)) {
      setErrorCode2(true);
    }
    if (/^[A-Z]+$/.test(code1) && /^[A-Z]+$/.test(code2)) {
      setErrorCode1(false);
      setErrorCode2(false);
      setIsLoading(true);
      const response = await apiService.getDistance(code1, code2);
      setIsLoading(false);
      if (response.success) {
        const factor = details[`${response.sectorLength}SectorFactor`];
        const nominalPay = details.nominalSectorPay;
        const total = nominalPay * factor + (lineTraining ? 20 : 0);
        const duty = {
          id: uuidv4(),
          departureAirport: code1,
          arrivalAirport: code2,
          distance: response.distance,
          sectorLength: response.sectorLength,
          paymentOwed: total,
        };
        dispatch({
          type: "ADD_SECTOR_DUTY",
          payload: duty,
        });
        setCode1("");
        setCode2("");
        setToast({
          title: "Sector Duty",
          message: `Sector sucessfully added`,
        });
      }
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <AirportCodeInput
          errorCode={errorCode1}
          value={code1}
          setCode={(e) => setCode1(e.target.value.toUpperCase())}
          first
        />
        <Arrow className={styles.arrow} />
        <AirportCodeInput
          errorCode={errorCode2}
          value={code2}
          setCode={(e) => setCode2(e.target.value.toUpperCase())}
        />
        <span className={styles.buttonContainer}>
          <ActionButton
            loading={isLoading}
            disabled={code1.length < 3 || code2.length < 3}
            buttonText={"ADD"}
            onClick={submit}
          />
        </span>
      </div>
      {details.trainerType && (
        <div className={styles.checkBoxContainer}>
          <CheckBox
            checked={lineTraining}
            onClick={() => setLineTraining(!lineTraining)}
            label="Line Training?"
          />
        </div>
      )}
    </div>
  );
}
