import React, { createContext, useState } from "react";

export const ContractDetailsContext = createContext();

const ContextManager = ({ children }) => {
  const [details, setDetails] = useState({});

  return (
    <ContractDetailsContext.Provider value={{ details, setDetails }}>
      {children}
    </ContractDetailsContext.Provider>
  );
};

export default ContextManager;
