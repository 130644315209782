export const getTimeDifference = (time1, time2) => {
  const startHour = parseInt(time1.slice(0, 2));
  const startMinute = parseInt(time1.slice(3, 5));
  const endHour = parseInt(time2.slice(0, 2));
  const endMinute = parseInt(time2.slice(3, 5));
  const convertedStartMinutes = startMinute / 60;
  const convertedEndMinutes = endMinute / 60;
  const startTime = startHour + convertedStartMinutes;
  const endTime = endHour + convertedEndMinutes;
  const time = endTime - startTime;
  if (time < 0) {
    return time + 24;
  }
  return time;
};

export const getVariablePay = (state) => {
  const sectorDutyPay = state.sectorDuties.reduce((total, duty) => {
    return (total = total + duty.paymentOwed);
  }, 0);

  const airportDutyPay = state.airportDuties.reduce((total, duty) => {
    return (total = total + duty.paymentOwed);
  }, 0);

  const leaveDayPay = state.leaveDays.paymentOwed || 0;
  const wfyDayPay = state.wfyDays.paymentOwed || 0;
  const nightStopPay = state.nightStops.paymentOwed || 0;
  const trainingDaysPay = state.trainingDays.paymentOwed || 0;
  const shortNoticeChangePay = state.shortNoticeChanges.paymentOwed || 0;
  const infringedDaysOffPay = state.infringedDaysOff.paymentOwed || 0;
  const disruptedDaysOffPay = state.disruptedDaysOff.paymentOwed || 0;
  const instructorDaysPay = state.instructorDays.paymentOwed || 0;

  const totalVariablePay =
    sectorDutyPay +
    airportDutyPay +
    leaveDayPay +
    wfyDayPay +
    nightStopPay +
    trainingDaysPay +
    shortNoticeChangePay +
    infringedDaysOffPay +
    disruptedDaysOffPay +
    instructorDaysPay;

  return totalVariablePay;
};
