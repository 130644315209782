import React, { useContext } from "react";
import Header from "../components/atoms/Header";
import { ContractDetailsContext } from "../context/contractDetailsContext";
import SectorsFlownSection from "../components/sections/SectorsFlownSection";
import SectorLogSection from "../components/sections/SectorLogSection";
import AirportsDutiesSection from "../components/sections/AirportDutiesSection";
import PaidDaysSection from "../components/sections/PaidDaysSection";
import PaidDayLogSection from "../components/sections/PaidDaysLogSection";
import CalculationSection from "../components/sections/CalculationSection";
import { ToastContext } from "../context/toastContext";
import SuccessToast from "../components/atoms/toasts/SuccessToast";

export default function FormPage() {
  const { details } = useContext(ContractDetailsContext);
  const { toast } = useContext(ToastContext);

  const rank = details.rank;

  return (
    <div>
      <Header />
      <SectorsFlownSection />
      <SectorLogSection />
      <AirportsDutiesSection />
      <PaidDaysSection leave />
      <PaidDaysSection wfy />
      <PaidDaysSection training />
      <PaidDaysSection snc />
      <PaidDaysSection nightStop />
      {details.trainerType && <PaidDaysSection instructor />}
      <PaidDaysSection captain={rank === "captain"} disrupted />
      <PaidDaysSection captain={rank === "captain"} infringed />
      <PaidDayLogSection />
      <CalculationSection />
      {!!toast && <SuccessToast toastDetails={toast} />}
    </div>
  );
}
