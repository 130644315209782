export const countries = [
  { option: "SELECT COUNTRY", value: "" },
  { option: "PORTUGAL", value: "portugal" },
];

export const ranks = [
  { option: "SELECT RANK", value: "" },
  { option: "Second Officer", value: "secondOfficer" },
  { option: "First Officer", value: "firstOfficer" },
  { option: "Senior First Officer", value: "seniorFirstOfficer" },
  { option: "Captain", value: "captain" },
  { option: "LTC", value: "captain-ltc" },
  { option: "TRI", value: "captain-tri" },
  { option: "TRE", value: "captain-tre" },
];

export const contractTypes = [
  { option: "CONTRACT TYPE", value: "" },
  { option: "FULL TIME FLEXI", value: "fullTimeFlexi" },
  { option: "FULL TIME 5/4", value: "fullTime54" },
  { option: "PART TIME 8/4", value: "partTime84" },
  { option: "PART TIME 9/3", value: "partTime93" },
];
