import React, { useState, useContext } from "react";
import apiService from "../../services/apiService";
import { ContractDetailsContext } from "../../context/contractDetailsContext";
import { countries, ranks, contractTypes } from "../../utils/dropdownValues";
import ActionButton from "../atoms/buttons/ActionButton";
import DropDown from "../inputs/DropDown";
import styles from "./SetupForm.module.css";

export default function SetupForm() {
  const [rank, setRank] = useState("");
  const [country, setCountry] = useState("");
  const [contractType, setContractType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [trainerType, setTrainerType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { setDetails } = useContext(ContractDetailsContext);

  const submit = async () => {
    setIsLoading(true);
    const response = await apiService.getPerDiemFactor(rank, contractType);
    setIsLoading(false);
    if (response.success) {
      response.country = country;
      response.rank = rank;
      response.trainerType = trainerType;
      if (trainerType === "ltc") {
        response.basicSalary =
          response.basicSalary + (response.basicSalary * 14 * 0.125) / 12;
      } else if (trainerType === "tri") {
        response.basicSalary =
          response.basicSalary * ((response.basicSalary * 14 * 0.15) / 12);
      } else if (trainerType === "tre") {
        response.basicSalary =
          response.basicSalary * ((response.basicSalary * 14 * 0.175) / 12);
      }
      setDetails(response);
    } else {
      setErrorMessage("OOPS SOMETHING WENT WRONG!");
    }
  };

  const selectRank = (value) => {
    if (value.includes("-")) {
      const splitValue = value.split("-");
      setRank(splitValue[0]);
      setTrainerType(splitValue[1]);
    } else {
      setTrainerType(null);
      setRank(value);
    }
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.formContent}>
        <DropDown setValue={(value) => setCountry(value)} options={countries} />
        <DropDown
          className={styles.alignRight}
          setValue={(value) => selectRank(value)}
          options={ranks}
        />
        <DropDown
          setValue={(value) => setContractType(value)}
          options={contractTypes}
        />
        <span className={styles.buttonContainer}>
          <ActionButton
            loading={isLoading}
            large
            buttonText={"LET'S GO!"}
            disabled={!rank || !contractType || !country}
            onClick={submit}
          />
        </span>
      </div>
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
    </div>
  );
}
