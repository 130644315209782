import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TimeInput from "../inputs/TimeInput";
import ActionButton from "../atoms/buttons/ActionButton";
import { ReactComponent as Arrow } from "../../assets/icons/right-arrow.svg";
import apiService from "../../services/apiService";
import { ContractDetailsContext } from "../../context/contractDetailsContext";
import { PerDiemsTotalContext } from "../../context/perDiemsTotalContext";
import styles from "./GeneralPairInputs.module.css";
import { getTimeDifference } from "../../utils/helperFunctions";
import { ToastContext } from "../../context/toastContext";

export default function TimePairInputs({ activated, resetActivationField }) {
  const [time1, setTime1] = useState("");
  const [time2, setTime2] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { details } = useContext(ContractDetailsContext);
  const { dispatch } = useContext(PerDiemsTotalContext);
  const { setToast } = useContext(ToastContext);

  const submit = async () => {
    setIsLoading(true);
    const time = getTimeDifference(time1, time2);
    const response = await apiService.getAirportDutyFactor(time, activated);
    setIsLoading(false);
    if (response.success) {
      const total = details.nominalSectorPay * response.sectorLength;
      const duty = {
        id: uuidv4(),
        reportTime: time1,
        checkoutTime: time2,
        paymentOwed: total,
        activated,
      };
      dispatch({
        type: "ADD_AIRPORT_DUTY",
        payload: duty,
      });
      setTime1("");
      setTime2("");
      resetActivationField();
      setToast({
        title: "Airport Duty",
        message: `Airport Duty succesfully added`,
      });
    }
  };

  return (
    <div className={styles.container}>
      <TimeInput
        value={time1}
        setTime={(e) => setTime1(e.target.value)}
        first
      />
      <Arrow className={styles.arrow} />
      <TimeInput value={time2} setTime={(e) => setTime2(e.target.value)} />
      <span className={styles.buttonContainer}>
        <ActionButton
          loading={isLoading}
          disabled={time1.length < 5 || time2.length < 5}
          buttonText={"ADD"}
          onClick={submit}
        />
      </span>
    </div>
  );
}
