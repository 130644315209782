import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ContractDetailsProvider from "./context/contractDetailsContext";
import PerDiemsTotalProvider from "./context/perDiemsTotalContext";
import ToastProvider from "./context/toastContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ContractDetailsProvider>
      <PerDiemsTotalProvider>
        <ToastProvider>
          <App />
        </ToastProvider>
      </PerDiemsTotalProvider>
    </ContractDetailsProvider>
  </React.StrictMode>
);
