import React from "react";
import DayCountField from "../fields/DayCountField";
import styles from "./GeneralSection.module.css";

export default function PaidDaysSection({
  leave,
  training,
  snc,
  instructor,
  nightStop,
  disrupted,
  infringed,
  captain,
  wfy,
}) {
  return (
    <div className={styles.container}>
      {leave && <h2 className={styles.sectionHeader}>LEAVE</h2>}
      {wfy && <h2 className={styles.sectionHeader}>WILLING TO FLY</h2>}
      {training && (
        <h2 className={styles.sectionHeader}>GROUND OR SIM TRAINING DAYS</h2>
      )}
      {snc && <h2 className={styles.sectionHeader}>SHORT NOTICE CHANGES</h2>}
      {nightStop && <h2 className={styles.sectionHeader}>NIGHT STOPS</h2>}
      {instructor && (
        <h2 className={styles.sectionHeader}>INSTRUCTOR DUTY DAYS</h2>
      )}
      {disrupted && (
        <h2 className={styles.sectionHeader}>DISRUPTED DAYS OFF</h2>
      )}
      {infringed && (
        <h2 className={styles.sectionHeader}>INFRINGED DAYS OFF</h2>
      )}
      <DayCountField
        leave={leave}
        training={training}
        snc={snc}
        instructor={instructor}
        nightStop={nightStop}
        disrupted={disrupted}
        infringed={infringed}
        captain={captain}
        wfy={wfy}
      />
    </div>
  );
}
