import React from "react";
import { ReactComponent as Checkmark } from "../../assets/icons/checkmark.svg";
import styles from "./CheckBox.module.css";

export default function CheckBox({ onClick, checked, label }) {
  return (
    <div onClick={onClick} className={styles.container}>
      <input className={styles.input} type="checkbox" checked={checked} />
      <div
        style={{
          border: checked ? "2px solid #1cd4e3" : "2px solid #707070",
          backgroundColor: checked ? "#1cd4e3" : "transparent",
        }}
        className={styles.box}
      >
        {checked && <Checkmark className={styles.checkMark} />}
      </div>
      <label className={styles.label}>{label}</label>
    </div>
  );
}
