import React from "react";
import { getDescription, getPayment } from "../../utils/payslipFunctions";
import styles from "./PayslipEntries.module.css";

export default function PayslipEntries({ duties }) {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });
  const dutiesArray = Object.keys(duties);
  dutiesArray.sort(collator.compare);

  return dutiesArray.map((duty) => {
    return (
      <div key={duty} className={styles.container}>
        <div className={styles.entry}>{duty}</div>
        <div className={styles.entry}>Generic Tax Dispensation</div>
        <div className={styles.entry}>{getDescription(duties[duty])}</div>
        <div className={styles.entry}>{getPayment(duties[duty])}</div>
        {
          // <div className={styles.entry}>Tax Free Amount</div>
        }
      </div>
    );
  });
}
