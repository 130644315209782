import React, { useEffect } from "react";
import styles from "./FlightDetailsPage.module.css";

export default function ResultPage() {
  useEffect(() => {
    const x = new XMLHttpRequest();
    x.open("GET", "/FLIC.aspx?id=ME-LISP1-12H_DEP", true);
    x.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(this.response, "text/html");
          const tables = doc.getElementsByTagName("tbody");
          const tableHolder = document.getElementById("table-holder");
          for (let i = 0, row; (row = tables[2].rows[i]); i++) {
            const airline = row.childNodes[1];
            if (airline.innerText === "EC") {
              airline.parentElement.className = "easy";
              tableHolder.appendChild(airline.parentElement);
            }
          }
        } else {
          console.log("NOT_ALLOWED: Can't cross this site URL.");
        }
      }
    };
    x.send();
  }, []);

  return (
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr>
          <td colSpan={2}>Flight Number</td>
          <td>Destination</td>
          <td>Registration</td>
          <td>Type</td>
          <td>Stand</td>
          <td>Gate</td>
          <td>STD</td>
          <td>ETD</td>
          <td>ATD</td>
          <td>Remarks</td>
        </tr>
      </thead>
      <tbody className={styles.tbody} id="table-holder"></tbody>
    </table>
  );
}
